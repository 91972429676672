
/* aim trainer */
/* AimTrainer.css */
body {
    /* background-color: #1e1f26; */
    color: #faebd7;
    font-family: "Montserrat", sans-serif;
  }
  
  h1 {
    line-height: 1.4;
    font-size: 4rem;
  }
  
  .btn {
    background-color: transparent;
    border: 3px solid #c0c0c0;
    border-radius: 3px;
    color: #faebd7;
    cursor: pointer;
    font-family: inherit;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
  }
  
  .screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }
  
  .lives .heart {
    width: 20px;
    height: 20px;
  }
  
  .board {
    cursor: url(../images/aim.png) 25 25, auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #031e2e;
  }