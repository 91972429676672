/* font */ 
@font-face {
  font-family: ScholarlyAmbition;
  src: url(../src/fonts/ScholarlyAmbition.ttf);
}

.ScholarlyAmbition {
  font-family: ScholarlyAmbition;
  color: white;
  text-shadow: -5px -3px #987070;
  letter-spacing: 2px;
  font-weight: 120;
  font-size: 70px;
}

.ScholarlyAmbitionShadow {
  font-family: ScholarlyAmbition;
  color: white;
  text-shadow: -5px -3px #987070;
  letter-spacing: 2px;
  font-weight: 120;
  font-size: 80px;
}

/* volume button */
.music-player {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: rgba(242, 140, 177, 1);
  padding: 16px;
  border-radius: 12px;
  color: white;
  text-align: center;
  width: 220px;
  position: fixed;
  bottom: 400px;
  left: 35%;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

/* Album Cover */
.album-cover {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
}

/* Song Title */
h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
}

/* Seek Bar */
.seek-bar {
  width: 90%;
  appearance: none;
  height: 4px;
  background: white;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  margin-top: 6px;
  margin: 10px; /* Remove unwanted spacing */
  padding: 0px; /* Fix extra space */
}

.seek-bar::-webkit-slider-thumb {
  appearance: none;
  width: 6px;
  height: 20px;
  background: #ed509f;
  border-radius: 5px;
  cursor: pointer;
}

/* Time Display */
.time-display {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  margin-top: 3px;
  opacity: 0.8;
}

/* Controls */
.controls {
  display: flex;
  gap: 15px;
  margin-top: 8px;
}

.controls button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white;
  transition: transform 0.2s ease-in-out;
}

.controls button:hover {
  transform: scale(1.2);
}

/* Volume Control */
.volume-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 8px;
}

.volume-icon {
  max-width: 25px; /* Adjust size */
  height: 25px;
  opacity: 1;
  margin-bottom: 12px;

}



/* Volume Slider */
.volume-slider {
  width: 70px;
  appearance: none;
  height: 2px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  margin-bottom: 15px;
  padding-left: 0px; /* Fix extra space */
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;

}

.volume-slider::-webkit-slider-thumb {
  appearance: none;
  width: 6px;
  height: 20px;
  background: #ed509f;
  border-radius: 2px;
  cursor: pointer;
}


/* home */

/* about me section */
.HeartContainer {
  position: absolute; 
  top: 15%;
  left: 18%;
  display: flex; 
  flex-direction: column; 
  z-index: 3;
}

.Heart01 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.Heart02 {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: -90px;
}

/* Contact Section */
.BunnyContainer {
  position: absolute; /* Moves the whole group */
  bottom: 8%; /* Adjust as needed */
  left: 40%; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}

.Bunny01 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.Bunny02 {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: -120px;
}

/* My Work Section */
.KirbyContainer {
  position: absolute; /* Moves the whole group */
  top: 10%; /* Adjust as needed */
  right: 10%; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.Kirby01 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.Kirby02 {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: -180px;
  margin-right: 350px;
}

.Heart01:hover, .Bunny01:hover, .Kirby01:hover {
  transform: scale(1.1); /* Slightly enlarges when hovered */
}




/* about me page*/

.SItextScholarlyAmbitionShadow {
  font-family: ScholarlyAmbition;
  color: white;
  text-shadow: -5px -3px #987070;
  letter-spacing: 2px;
  font-weight: 120;
  font-size: 80px;
  margin-top: 1%;
  margin-left: 10%;
}




.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.joliaphoto {
  display: flex;
  position: relative;
  justify-content: center;
  justify-items: center;
}
/* memopad */
.memopad {
  position: relative;
  padding: 8vw;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-image: url(images/memopad01.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  scale: 90%;
}

.memopadtext {
  position: inherit;
  top: -40px;
  left: -8%;
  z-index: 2;
  width: 500px;
  font-family: ScholarlyAmbition;
  justify-content: center;
  text-align: center;
  color: #C4A2B1;
  /* text-shadow: -1px -1px #987070; */
  letter-spacing: .5px;
  font-weight: 120;
  font-size: 30px;
}


/* MyWork.css */
.container1 {
  /* display: flex; */
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
  /* Use flexbox layout */
}

.box {
  width: 20vw;
  /* Adjust width as needed */
  height: 22vh;
  /* Adjust height as needed */
  background-color: #BA8DA5;
  border-radius: 40px;
  /* Adjust the value to control the roundness of the corners */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  font-family: ScholarlyAmbition;
  color: white;
  text-shadow: -5px -3px #987070;
  letter-spacing: 2px;
  font-weight: 120;
  font-size: 400%;
}

a {
  text-decoration: none;
}

.gridoffour {
  display: grid;
  width: 52vw;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 60px;
  margin-left: 300px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.div3 {
  grid-area: 2 / 1 / 3 / 2;
}

.div4 {
  grid-area: 2 / 2 / 3 / 3;
}

.Kirby03Container {
  position: fixed;
  top: 25%;
  right: 10%;
  margin-left: 80%;
}

.navbar {
  color: #BA8DA5;
  flex-direction: row;
  display: flex;
}

/* kirby at welcome page */
.KirbyStart {
    display: flex;
    justify-content: space-evenly;
    margin-top: 8%;
    flex-direction: column;
    align-items: center;
}

.animated-arrow {
  width: 500px;  
  animation: arrow-animation 2s ease-in-out infinite;
  position: absolute;
  margin-bottom: -150px;  
  margin-right: 600px;
}

@keyframes arrow-animation {
    0%, 100% {
        transform: translateX(0) translateY(0) rotate(25deg);
    }
    50% {
        transform: translateX(20px) translateY(20px) rotate(35deg);
    }
}


/* hello kitty background */
#header {
  height: 100vh;
  width: 100%;
  background-image: url(images/hellokittybackground.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

body {
  margin: 0;
  padding: 0;
  background-color: #F8CDE1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* navbar */
.navbar {
  /* Set your desired background color */
  color: #fff;
  /* Set your desired text color */
  padding: 0px 0;
}

.container {
  background-color: #BA8DA5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 15vh;
}

.nav-elements ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-elements ul li {
  margin-right: 5vw;
  /* Adjust spacing between nav items */
}

.nav-elements ul li:last-child {
  margin-right: 10vw;
}

.nav-elements ul li a {
  /* Set link color */
  text-decoration: none;
  font-size: 36px;
  /* Set font size */
  font-family: ScholarlyAmbition;
  color: white;
  text-shadow: -5px -3px #987070;
  letter-spacing: 2px;
  font-weight: 120;
  font-size: 60px;
}

.logo {
  margin-left: 10vw;
}

/* underline animation */

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #987070;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #987070;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* photo grids */

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
  gap: 15px; 
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
}

.photo-item {
  width: 100%;
  aspect-ratio: 1; /* Makes all images square */
  object-fit: cover; /* Ensures the image fills the space while maintaining its aspect ratio */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.photo-item:hover {
  transform: scale(1.05); /* Slightly enlarge the image on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  display: flex;
  flex-direction: column; /* Ensures image and text stack vertically */
  align-items: center; /* Centers everything inside */
  justify-content: center;
  position: relative;
  width: 80%;
  max-width: 900px;
  max-height: 90vh;
  padding: 20px;
}

.modal-content img {
  width: 80%; /* Ensures proper size */
  object-fit: contain;
  max-height: 70vh;
}

/* Center "Click to close" ONLY below the image */
.modal-click-text {
  margin-top: 10px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.6);
  padding: 6px 12px;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.5px;
  width: max-content;
}


.modal-content::after {
  content: 'Click anywhere to close';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 6px 12px;
  border-radius: 6px;
  width: max-content;
  letter-spacing: 0.5px;
}


.modal:hover {
  cursor: pointer;
}

.photo-item {
  width: 100%;
  height: auto;
  object-fit: cover;
  scale: 100%;
}

.description {
  max-width: 60vh;
  padding: 15px;
  margin-top: 10px;
  margin-left: 10px;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  text-align: center;
  line-height: 1.2;
  background: rgba(255, 255, 255); /* Adds slight background for readability */
  border-radius: 8px;
}


/* contact page */

.SAStitles {
  font-family: ScholarlyAmbition;
  color: #ba8da4;
  text-shadow: -1px -1px #987070;
  letter-spacing: 2px;
  font-weight: 120;
  font-size: 60px;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5deeb;
  padding: 10px; /* Adds some spacing inside */
  width: 70%; 
  max-width: 1000px; 
  border-radius: 10px; 
  box-shadow: 4px 4px 10px #987070; /* Optional: Adds a subtle shadow */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Perfectly centers it */
}

.message-me-container {
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;  
  top: 0px;
  padding: -0px;
}

.contact-form {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 0px; /* Avoid negative margins */
  align-items: center;
  top: -50px;
}

.contact-form div {
  margin-bottom: 15px;
}

input, textarea {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px;
  background-color: #BA8DA5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  pointer-events: auto; /* Ensures the button can be clicked */
  display: block; /* Ensures button is visible */

}

button:hover {
  background-color: #945a79;
}

.contact-info {
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;  
  top: 50px;
  padding: -0px;

}

.contact-info-links {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 0px; /* Avoid negative margins */
  align-items: center;
  top: -60px;
  padding: -0px;
  color: #987070;
}


.ScholarlyAmbitionShadowContactPage {
  all: unset;
  position: relative;
  left: -2%;
  z-index: 2;
  width: 500px;
  font-family: ScholarlyAmbition;
  justify-content: center;
  text-align: center;
  color: #945a79;
  /* text-shadow: -1px -1px #987070; */
  letter-spacing: .5px;
  font-weight: 60;
  font-size: 30px;
  margin-bottom: 15px;
}
/* Grid layout */
.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 50px;
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 50px;
}

.game-item {
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.game-item:hover {
  transform: scale(1.05);
}

.game-thumbnail {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

/* Game details on hover */
.game-details {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  opacity: 1;
}

.game-details h3 {
  margin: 10px 0;
  font-size: 1.5rem;
}

.game-details p {
  margin-bottom: 20px;
  text-align: center;
}

.game-links a {
  margin: 5px;
  padding: 10px 20px;
  background-color: #ff4081;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.game-links a:hover {
  background-color: #e6006b;
}

/* car vids */

.carvids-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b686a3; /* Match the pinkish-purple background */
  border-radius: 20px;
  margin-left: 200px;
  margin-top: 50px;
  padding: 20px;
  width: 700px;
  color: white;
  font-family: 'Arial', sans-serif;
}

.carvids-text-content h1 {
  margin: 0;
  font-size: 2rem;
}

.carvids-text-content p {
  margin: 5px 0 0 0;
  font-size: 1.2rem;
}

.carvids-image-placeholder {
  width: 100px;
  height: 100px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: black;
  font-size: 0.9rem;
  background-image: url('../src/images/miata.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.project-item {
  /* Base styling for the item */
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.project-item:hover {
  transform: scale(1.05); /* Grows slightly on hover */
}

/* Mobile Styles - For screens smaller than 1000px */
@media (max-width: 1000px) {
  #header {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto; /* Enables scrolling */
    height: 100vh;
    padding-bottom: 100px; /* Prevents music player from covering content */
  }

  .KirbyStart {
    display: flex;
    justify-self: center;
    margin-top: 50%;
    flex-direction: column;
    align-items: center;
    width: 80vw;
}


  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    overflow-y: auto;
  }

  .Heart01, .Bunny01, .Kirby01 {
    transform: scale(1.5);
  }

  .HeartContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px;
    text-align: center;
    top: 200px; /* Adjusted to give proper spacing */
  }

  .Heart01 {
    margin-bottom: 0px;
  }

  .Heart02 {
    margin-top: -40px;
  }

  .BunnyContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px;
    text-align: center;
    top: 1500px; /* Increased to create space */
  }

  .Bunny01 {
    margin-bottom: 0px;
  }

  .Bunny02 {
    margin-top: -70px;
  }

  .KirbyContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px;
    text-align: center;
    top: 750px; /* Further down for spacing */
  }

  .Kirby01 {
    margin-bottom: 15px;
  }

  .Kirby02 {
    margin-top: 0px;
    margin-left: 230px; /* Remove offset so it stays centered */
  }

  .music-player {
    display: none;
  }




  /* Navigation Bar */
  .logo {
    display: none;
  }

  .nav-elements {
    width: 100%;
    display: flex;
    justify-content: center;
  }


  .nav-elements ul li a {
      font-size: 50px;
      padding-left: 40px;
  }

  .container {
    background-color: #BA8DA5;
    display: flex;
    justify-content: center; /* Center navbar elements */
    align-items: center;
    width: 100%; /* Use 100% instead of 100vw */
    height: 10vh; /* Ensure it has a height */
    position: fixed; /* Keep navbar fixed on mobile */
    top: 0;
    left: 0;
    z-index: 1000;
    padding-left: 20px;
  }
  
  .navbar {
    margin-bottom: 300px;
  }


  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content */
    width: 100%;
    height: auto;
    overflow-y: auto; /* Enables scrolling */
  }

  /* Make Memopad First (On Top) */
  .memopad {
    width: 90%; /* Keeps it responsive */
    height: 800px; /* Adjust height to make sure it doesn’t shrink */
    background-image: url(images/memopad01.jpg);
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the full image is visible */
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .memopadtext {
    position: relative; /* Allows natural placement */
    top: auto;
    left: auto;
    width: 100%;
    font-size: 40px; /* Make text more readable */
    text-align: center;
  }

  /* Make Icons (Row) Second - Move Down */
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 100px; /* Moves icons further down */
  }

  .iconsandme {
    scale: (2.5);
  }




  /* Contact Page */
  .contact-container {
      flex-direction: column;
      width: 90%;
      padding: 20px;
      position: static;
      transform: none;
      margin: 0 auto;
      text-align: center;
  }

  /* Adjusting Grid for Mobile */
  .gridoffour {
      width: 90vw;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      margin-left: auto;
      margin-right: auto;
  }

  /* Projects Section */
  .games-grid {
      grid-template-columns: 1fr;
      padding: 20px;
  }

  /* Fix Image Scaling */
  .game-thumbnail {
      height: 250px;
  }

  /* Center Images */
  .photo-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

    /* Adjust Carvids Card */
    .carvids-card-container {
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
